<template>
	<r-flex align="flex-end">
		<span class="r-check-no log-out-btn" @click="logOut"></span>
	</r-flex>
	<r-data-list
		:headdata="headdata"
		:listdata="listData.data"
		:opt="opts"
		v-if="loaded"
		:searchfilter="searchfilter"
		v-model:qu="q"
		v-model:editMode="isEditMode"
		v-model:editLineId="editLineId"
	>
		<template v-slot:col1="{ datarow, idrow }" v-if="!isEditMode">
			<span
				class="r-check-ok small"
				v-if="datarow.isActive === true && editLineId !== idrow"
			></span>
			<span
				class="r-check-no small"
				v-if="datarow.isActive === false && editLineId !== idrow"
			></span>
		</template>
	</r-data-list>
</template>
<script>
	import { onBeforeMount, ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import router from '@/router/'
	import * as Cookies from 'js-cookie'
	import { trToUpper } from '@/mix/global'
	export default {
		setup() {
			const store = useStore()
			const listData = ref({})
			const loaded = ref(false)
			const q = ref('')
			const isEditMode = ref(false)
			const editLineId = ref(null)
			const searchfilter = (v) => {
				return (
					(v.name && trToUpper(v.name).includes(trToUpper(q.value))) ||
					(v.deviceId && trToUpper(v.deviceId).includes(trToUpper(q.value))) ||
					(v.city && trToUpper(v.city).includes(trToUpper(q.value))) ||
					(v.projectName &&
						trToUpper(v.projectName).includes(trToUpper(q.value)))
				)
			}
			const headdata = computed(() => {
				return [
					{
						title: 'Aktif mi?',
						style: {
							width: '10%',
							justifyContent: 'center',
							textAlign: 'center',
						},
						key: 'isActive',
						type: 'checkbox',
					},
					{
						title: 'Ad-Soyad',
						style: { width: '20%' },
						key: 'name',
					},
					{
						title: 'Device ID',
						style: { width: '20%' },
						key: 'deviceID',
						edit: false,
					},
					{
						title: 'Şehir',
						style: { width: '15%' },
						key: 'city',
					},
					{
						title: 'Proje',
						style: { width: '25%' },
						key: 'projectName',
					},
				]
			})
			const opts = ref({
				title: 'Kullanıcılar',
				count: true,
				buttons: {
					style: { width: '10%' },
					edit: {
						type: 'local',
						savefunc: (d) => {
							saveLine(d)
						},
					},
					delete: {
						func: (d) => {
							deleteFunc(d)
						},
					},
				},
			})
			const getList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'appusers/getallappusers',
				})
				return list
			}
			const deleteFunc = async (d) => {
				await store.dispatch('postDatas', {
					api: 'appusers/deleteappuser?appuserid=' + parseInt(d.row.id),
				})
				listData.value = await getList()
			}
			const saveLine = async (d) => {
				await store.dispatch('postDatas', {
					api: 'appusers/addappuser',
					data: d,
				})
				listData.value = await getList()
			}
			const logOut = () => {
				Cookies.remove('aysarsiv')
				router.push('/giris')
			}
			onBeforeMount(async () => {
				listData.value = await getList()
				loaded.value = true
			})
			return {
				headdata,
				opts,
				listData,
				loaded,
				q,
				searchfilter,
				logOut,
				isEditMode,
				editLineId
			}
		},
	}
</script>
